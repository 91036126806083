@media (max-width: 768px) {

.search-box {
    width: 85vw;

    &__input-text {
        margin: 0;
        padding: 0 8px 0 16px;
    }

    &__input-text::-webkit-input-placeholder { /* Safari and Chrome */
        font-size: 1rem;
        font-weight: 100;
        color: #a0b0b2;
        padding: 0;
      }

    &__button {
        font-size: 1.2rem;
        margin-right: 16px;
        width: 35%;
        margin-top: 3px;
    }
}
.search-suggestions-container {
    &__list-visible { 
        width: 90%;
    }
}
}
@media (max-width: 768px) {
.dollar-value-input__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    height: 48px;
    width: 180px;
    box-shadow: 0 0px 20px rgba(0, 0, 0, 0.6);
    border-radius: 12px;

    button:hover {
        font-size: 1.6rem
    }
}
}
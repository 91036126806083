$ff-mtg: 'MTGFont', sans-serif;

.search-box {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    align-items: center;
    width: 55%;
    max-width: 512px;
    &__with-results {
        display: flex;
        width: 55%;
        flex-direction: column;
        align-items: center;
        margin-top: 32px;
    }

    &__input-container {
        display: flex;
        position: relative;
        width: 100%;
        max-width: 512px;
        height: 64px;
        background: linear-gradient(90deg, rgba(202, 214, 226, 0.35), transparent);
        box-shadow: -12px -20px 56px 0px rgba(232, 237, 243, 0.05), 36px 12px 64px 0px rgba(2, 3, 3, 0.70), -16px -6px 80px 0px rgba(248, 249, 249, 0.03) inset;
        border-radius: 20px;
        z-index: 100;
    }
    
    //Rim light border for the input container
    &__input-container::before {
        content: "";
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 20px;
        border: 1px solid transparent;
        background: linear-gradient(120deg, rgba(202, 214, 226, 0.65), transparent) border-box;
        box-shadow: -12px -20px 56px 0px rgba(232, 237, 243, 0.05), 36px 12px 64px 0px rgba(2, 3, 3, 0.70), -16px -6px 80px 0px rgba(248, 249, 249, 0.03) inset;
        mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        z-index: 0;
    }

    &__input-text-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width:100%
    }

    &__input-text {
        position: relative;
        background: transparent;
        border-radius: 12px 0 0 12px;
        width: 100%;
        border: none;
        margin-top: 4px;
        padding: 2px 22px;
        color: white;
        font-family: $ff-mtg;
        font-size: x-large;
        font-weight: 600;
        outline: none;
        z-index: 2;
    }
    &__input-text::-webkit-input-placeholder { /* Safari and Chrome */
        font-size: large;
        font-weight: 100;
        color: #a0b0b2;
      }
      
    &__input-text-disabled {
        position: relative;
        background: transparent;
        border-radius: 12px 0 0 12px;
        width: 100%;
        border: none;
        padding: 2px 16px;
        margin-top: -1px;
        color:#7f989d;
        font-family: $ff-mtg;
        font-size: x-large;
        font-weight: 600;
        outline: none;
        z-index: 2;
    }

    &__button {
        display: flex;
        width: 200px;
        color: white;
        font-weight: 600;
        font-size: 1.4rem;
        font-family: $ff-mtg;
        border: none;
        height: 100%;
        background: transparent;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1000;
        transition: font-size 0.1s ease-in-out;
    }

    &__button-disabled {
        display: flex;
        width: 200px;
        color: #7f989d;
        font-weight: 600;
        font-size: 1.4rem;
        font-family: $ff-mtg;
        border: none;
        height: 100%;
        background: transparent;
        align-items: center;
        justify-content: center;
        cursor: progress;
        z-index: 1000;
    }

}

.search-suggestions-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__list-visible {
      position: relative;
      list-style: none;
      width: 94%;
      padding: 12px 0 0 0;
      margin-top: -12px;
      border-radius: 0 0 6px 6px;
      z-index: 100;
    }

    &__list-invisible {
        display: none;
    }

    &__result-item:not(:last-child) {
        border-bottom: 1px solid #e0f0f3;
    }
    &__result-item:last-child {
        border-radius: 0 0 6px 6px;
    }
    &__result-item {
        background: white;
        color: black;
        font-size: large;
        font-weight: 600;
        line-height: 32px;
        padding-left: 8px;
        cursor: pointer;
      }

  }

  
@media (hover: hover) {
    .search-box__button:hover {
        font-size: 1.6rem;
        color: rgb(29, 33, 36);
        background: linear-gradient(120deg, rgba(white, 0.4) 10%, transparent 70%);
        border-radius: 0 20px 20px 0;
        border: 1px solid rgb(188, 196, 199);
    }
    .search-suggestions-container__result-item:hover {
        background: #374353;
        color: white;
      }
}

@keyframes search-box-animation {
    0% {
        scale: 0;
    }
    50% {
        scale: 1.1;
    }
    100% {
        scale: 1;
    }
}

@keyframes buttonLoading {
    50% {
        background: transparent;
        background-image: url('../../../assets/search-button-loading-active.png');
        background-size: cover;
    }
    100% {
        background: transparent;
        background-image: url('../../../assets/search-button-loading.png');
        background-size: cover;
    }
}

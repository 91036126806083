$page-background: url("./assets/page-bg13.jpg");

@font-face {
  font-family: "MTGFont";
  src: url("./assets/Beleren2016-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "GilroyLight";
  src: url("./assets/Gilroy-Light.otf") format("truetype");
}

@font-face {
  font-family: "GilroyExtraBold";
  src: url("./assets/Gilroy-ExtraBold.otf") format("truetype");
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  width: 100svw;
  margin: auto;
  font-family: "MTGFont", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

div#root {
  width: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.curtain-on {
  position: absolute;
  background: black;
  width: 100svw;
  height: 100svh;
  z-index: 100000;
  backdrop-filter: blur(40px);
  animation: intro 2s ease forwards;
}

@keyframes intro {
  100% {
    background: transparent;
    backdrop-filter: blur(0);
    z-index: -2000;
  }
}

.mtg-tutor__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: $page-background;
  background-position: center 30%;
  background-size: cover;
  z-index: -100000;
}

.mtg-tutor__background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
}

.mtg-tutor__background::after {
  content: "";
  position: absolute;
  background-color: rgba($color: #000000, $alpha: 0.2);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mtg-tutor__main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 32px;
  .header {
    display: flex;
    font-size: 3.2rem;
    position: relative;
    font-weight: 1000;
    font-family: "MTGFont", sans-serif;
    z-index: 1;
    color: white;
  }

  .header-image {
    width: auto;
    max-height: 115px;
    margin-left: 300px;
  }

  .subtitle {
    margin: 32px 0 0 0;
    text-align: center;
    position: relative;
    color: white;
    font-size: 1.2rem;
    font-family: "GilroyLight", sans-serif;
    font-weight: 500;
    line-height: 28px;
  }
}

@media (max-width: 768px) {
  body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100vw;
    margin: auto;
    font-family: "MTGFont", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .mtg-tutor__main-container {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 32px;
      font-size: 2rem;
      position: relative;
    }

    .header-image {
      width: auto;
      max-height: 80px;
      margin: 0;
    }
    .subtitle {
      margin: 0 12px;
      text-align: center;
      font-size: 0.9rem;
      line-height: 16px;
    }
  }
}

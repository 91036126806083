.loading-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate 1s linear infinite;
    gap: 8px;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    z-index: 12000;
  }


  .circle {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
  }

  .red {
    background-color: rgba(201, 59, 54, 0.75);
    animation: firstAnimation 1200ms ease-in-out infinite;
  }

  .black {
    background-color: rgba(37, 36, 36, 0.75);
    animation: secondAnimation 1200ms ease-in-out infinite;
  }

  .blue {
      background-color: rgba(42, 152, 195, 0.75);
      animation: thirdAnimation 1200ms ease-in-out infinite;
    }

  .white {
      background-color: rgba(240, 224, 199, 0.75);
      animation: fourthAnimation 1200ms ease-in-out infinite;
    }

  .green {
    background-color: rgba(44, 102, 80, 0.75);
    animation: fifthAnimation 1200ms ease-in-out infinite;
  }

  @keyframes firstAnimation {
    0% {
        scale: 1;
    }
    10%{
        scale: 1.5;
    }
    20% {
        scale: 1;
    }
  }

  @keyframes secondAnimation {
    20% {
        scale: 1;
    }
    30%{
        scale: 1.2;
    }
    40% {
        scale: 1;
    }
  }

  @keyframes thirdAnimation {
    40% {
        scale: 1;
    }
    50%{
        scale: 1.2;
    }
    60% {
        scale: 1;
    }
  }
  @keyframes fourthAnimation {
    60% {
        scale: 1;
    }
    70%{
        scale: 1.2;
    }
    80% {
        scale: 1;
    }
  }
  @keyframes fifthAnimation {
    80% {
        scale: 1;
    }
    90%{
        scale: 1.2;
    }
    100% {
        scale: 1;
    }
  }
